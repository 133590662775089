import React from "react";

import Checkbox from "../../components/ui/Checkbox/Checkbox";
import { pricingUrl } from "../../library/routes";

import * as s from "./PGTable.module.css";

export function OptionsPanel({
  priceListId,
  countryCode,
  currency,
  params,
  navigate,
  purchaseExchangeRates,
  saleExchangeRate,
  currentSaleRate
}) {
  const { showDiff, showDiffPercent, modelExRate } = params;

  return (
    <div className={s.optionsPanel}>
      <div className="flex">
        <Checkbox
          id="showDifference"
          label="ZOBRAZIT ROZDÍLY"
          labelClass={s.checkboxLabel}
          className="mr-8"
          labelPositionLeft={true}
          checked={showDiff}
          disabled={showDiffPercent && modelExRate}
          onChange={() => {
            navigate(
              pricingUrl(priceListId, countryCode, {
                ...params,
                showDiff: !showDiff
              })
            );
          }}
        />
        <Checkbox
          id="showDifferencePercent"
          label="ZOBRAZIT ROZDÍLY %"
          labelClass={s.checkboxLabel}
          labelPositionLeft={true}
          checked={showDiffPercent}
          disabled={modelExRate && showDiff}
          onChange={() => {
            navigate(
              pricingUrl(priceListId, countryCode, {
                ...params,
                showDiffPercent: !showDiffPercent
              })
            );
          }}
        />
      </div>
    </div>
  );
}
