import { useMutation } from "react-query";
import { fetch } from "../library/fetch";
import { pricingQueryKey } from "./getPricing";
import { injectValidationErrors } from "../store/reducers/validations";
import { queryClient } from "../library/queryClient";

export function useAssignMatrixToPricingGroup({
  priceListId,
  countryCode,
  ...urlParams
}) {
  return useMutation(
    payload => {
      const { pricingGroupId, matrixId } = payload;
      return fetch(
        {
          method: "PUT",
          url: `/pricing-list/${priceListId}/pricing-group/${pricingGroupId}/country/${countryCode}/matrix/${matrixId}`
        },
        { pricingGroupId, matrixId }
      );
    },
    {
      onSuccess: ({ data }) => {
        const key = pricingQueryKey({
          priceListId,
          countryCode,
          ...urlParams
        });
        queryClient.setQueryData(key, prevData => {
          const updatedItems = prevData.items.map(item =>
            item.id === data.id ? data : item
          );
          return { ...prevData, items: injectValidationErrors(updatedItems) };
        });
      }
    }
  );
}
