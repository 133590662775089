import React from "react";
import Text from "../../components/ui/Text/Text";

import * as s from "./PGTable.module.css";

export default function SalesExchangeRateInput({
  currency,
  disabled,
  onChange,
  exchangeRate, // value
  purchaseExchangeRates, // ~label
}) {
  return (
    <div className="flex mr-2" key={currency}>
      <div className={s.exchangeRate}>
        <Text size=".75rem" className={s.exchangeRateLabel}>
          {currency}
        </Text>
        <Text
          size=".75rem"
          className={s.exchangeRateValue}
          weight="600"
        >
          {purchaseExchangeRates[currency]}
        </Text>
        <input
          disabled={disabled}
          value={exchangeRate}
          className={s.inputNumber}
          onChange={onChange}
        />
      </div>
    </div>
  );
}