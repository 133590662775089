import { baseTitle } from "../../screens/Pricing/PGTable";
import { groupBy, orderBy } from "lodash";
import { translateFromLevel } from "../../screens/Matrix/Configuration";

const cheaperColorfulErr =
  "Cena barevné ceníkové skupiny musí být větší než bílé.";

const XL3_SIZE = 18;

function findSequenceError(coll) {
  let prevCol = { value: Infinity };

  return coll
    .map(level => {
      prevCol = { value: Infinity };
      const errorRow = level.find(col => {
        const error = col.value > prevCol.value;
        if (!error) prevCol = col;
        return error;
      });
      return errorRow ? { errorRow, prevCol } : false;
    })
    .filter(Boolean);
}

function filterOutOfRangeValues(priceListValues, maxLevel, maxLimit) {
  return (priceListValues || []).filter(
    ({ level, limit }) =>
      translateFromLevel(level) <= maxLevel && limit <= maxLimit
  );
}

export function injectValidationErrors(data) {
  data.forEach((row, ridx) => {
    const currentVals = row.price_list_group_values;
    currentVals.forEach((item, i) => {
      const prevRow = data[ridx - 1];
      const prevValsForCheck = prevRow?.price_list_group_values;
      if (
        row.maxSize >= XL3_SIZE &&
        prevValsForCheck?.length &&
        item.value < prevValsForCheck[i].value
      ) {
        const xl = prevRow.xl;
        item.errorMsg = [
          `Hodnota hladiny ${row.xl}XL musí být větší než hodnota hladiny ${
            xl ? `${xl}XL` : "standardní velikosti"
          }`
        ];
      } else {
        item.errorMsg = [];
      }
    });
  });

  const rows = data.map((row, rowIdx) => {
    let newRow = row;
    const matrix = row.configuration_matrix?.matrix;
    const maxLimit = matrix?.[0]?.length;
    const maxLevel = matrix?.length;
    const title = baseTitle(row.title);
    const prevValsForCheck =
      rowIdx > 0 &&
      baseTitle(data[rowIdx - 1].title) === title &&
      row.is_colorful
        ? data[rowIdx - 1].price_list_group_values
        : false;
    const currentVals = row.price_list_group_values;
    let newCurrentVals = currentVals.map((item, i) => {
      const errors = item.errorMsg || [];
      if (i > 0 && item.value > currentVals[i - 1].value)
        errors.push("Hodnota hladiny musí být menší nebo rovna předchozí");

      if (prevValsForCheck?.length && prevValsForCheck[i] && item.value < prevValsForCheck[i].value)
        errors.push(cheaperColorfulErr);

      return {
        ...item,
        errorMsg: errors.join(" | ")
      };
    });

    const verticalErrors = findSequenceError(
      Object.values(
        groupBy(
          orderBy(
            filterOutOfRangeValues(row.all_values, maxLevel, maxLimit),
            ["limit", "level"]
          ),
          "limit"
        )
      )
    );
    const horizontalErrors = findSequenceError(
      Object.values(
        groupBy(
          orderBy(
            filterOutOfRangeValues(row.all_values, maxLevel, maxLimit),
            ["level", "limit"]
          ),
          "level"
        )
      )
    );
    const errors = verticalErrors.concat(horizontalErrors);
    if (errors.length > 0) {
      const rowErrorMsg = errors
        .map(
          ({ errorRow, prevCol }) =>
            `Hodnota hladiny ${errorRow.value} (${errorRow.level}${errorRow.limit}) musí být menší nebo rovna ${prevCol.value} (${prevCol.level}${prevCol.limit})`
        )
        .join(" | ");
      newRow = { ...newRow, rowErrorMsg };
    }

    return { ...newRow, price_list_group_values: newCurrentVals };
  });

  return rows;
}
