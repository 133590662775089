import { useMutation } from "react-query";
import { fetch } from "../library/fetch";
import { pricingQueryKey } from "./getPricing";
import { sortBy, cloneDeep } from "lodash";
import { injectValidationErrors } from "../store/reducers/validations";
import { queryClient } from "../library/queryClient";

export function useUpdatePricingGroupLeadingValue({
  priceListId,
  countryCode,
  ...urlParams
}) {
  const query = {
    method: "PUT",
    url: `/pricing-list/${priceListId}/country/${countryCode}/pricing`
  };
  return useMutation(
    payload => {
      const { pricingGroupId, value, x, y } = payload;
      return fetch(query, { pricing_group_id: pricingGroupId, value, x, y });
    },
    {
      onSuccess: ({ data }, { pricingGroupId }) => {
        const key = pricingQueryKey({
          priceListId,
          countryCode,
          ...urlParams
        });
        queryClient.setQueryData(key, prevData => {
          const items = prevData.items.map(item => {
            if (item.id === pricingGroupId) {
              const newValues = item.price_list_group_values.reduce(
                (newVals, oldVal) => {
                  const val = newVals.find(
                    p => p.level === oldVal.level && p.limit === oldVal.limit
                  );
                  return val ? newVals : [...newVals, oldVal];
                },
                data.price_list_group_values
              );
              return {
                ...item,
                all_values: data.all_values,
                rowErrorMsg: null,
                price_list_group_values: sortBy(newValues, ["level", "limit"]) // needed for validations
              };
            }
            return { ...item };
          });
          const validations = [];
          return {
            ...prevData,
            validations,
            items: injectValidationErrors(cloneDeep(items))
          };
        });
      }
    }
  );
}
