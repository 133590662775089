import React from "react";
import { Redirect, Router } from "@reach/router";
import { signInUrl } from "./library/routes";
import NotFound from "./screens/NotFound";
import SignIn from "./screens/SignIn";
import PriceLists from "./screens/PriceLists";
import PriceListDashboard from "./screens/PriceListDashboard";
import Matrix from "./screens/Matrix";
import { ModalPortal } from "./components/Modals";
import Limits from "./screens/Limits";
import PricingGroups from "./screens/PricingGroups";
import Pricing from "./screens/Pricing";
import PricingC from "./screens/Pricing/index-c";
import ExchangeRates from "./screens/ExchangeRates";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./library/queryClient";

export const DispatchContext = React.createContext();
export const ErrorsContext = React.createContext();
export const ReadOnlyContext = React.createContext();

export default function App({ modalElement, ...pass }) {
  const { state } = pass;
  const isAuthenticated = Boolean(state.app["access-token"]);

  const dispatch = pass.dispatch;

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <DispatchContext.Provider value={dispatch}>
        <ErrorsContext.Provider value={state.app.serverError}>
          <ReadOnlyContext.Provider
            value={state.requests.session?.data?.user?.groups?.includes(
              "READER"
            )}
          >
            {isAuthenticated ? (
              <Router>
                <Redirect from={signInUrl()} to="/" noThrow />
                <PriceLists path="/" {...pass} />
                <PriceListDashboard path="/price-list/:priceListId" {...pass} />
                <Pricing
                  path="/price-list/:priceListId/country/:countryCode"
                  {...pass}
                />
                <PricingC
                  path="/price-list-currency/:priceListId"
                  currencyPickerMode
                  {...pass}
                />
                <Matrix path="/matrix/:countryCode" {...pass} />
                <Matrix path="/matrix" {...pass} />
                <Limits path="/limits/:countryCode" {...pass} />
                <Limits path="/limits" {...pass} />
                <PricingGroups
                  path="/pricing-groups/:pricingGroupId"
                  {...pass}
                />
                <PricingGroups path="/pricing-groups" {...pass} />
                <ExchangeRates path="/exchange-rates" {...pass} />
                <NotFound default {...pass} />
              </Router>
            ) : (
              <Router>
                <Redirect from="/" to={signInUrl()} noThrow />
                <SignIn path={signInUrl()} {...pass} />
                <NotFound default {...pass} />
              </Router>
            )}
            <ModalPortal {...pass} element={modalElement} />
          </ReadOnlyContext.Provider>
        </ErrorsContext.Provider>
      </DispatchContext.Provider>
    </QueryClientProvider>
  );
}
