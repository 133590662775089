import { useMutation } from "react-query";
import { fetch } from "../library/fetch";
import { pricingQueryKey } from "./getPricing";
import { injectValidationErrors } from "../store/reducers/validations";
import { queryClient } from "../library/queryClient";

export function useDismissMatrixToPricingGroup({
  priceListId,
  countryCode,
  ...urlParams
}) {
  return useMutation(
    payload => {
      const { pricingGroupId } = payload;
      return fetch(
        {
          method: "DELETE",
          url: `/pricing-list/${priceListId}/pricing-group/${pricingGroupId}/country/${countryCode}/matrix`
        },
        { pricingGroupId }
      );
    },
    {
      onSuccess: ({ data }, { pricingGroupId }) => {
        const key = pricingQueryKey({
          priceListId,
          countryCode,
          ...urlParams
        });
        queryClient.setQueryData(key, prevData => {
          const groupId = Number(pricingGroupId);
          const updatedItems = prevData.items.map(item => {
            if (item.id !== groupId) return item;
            return {
              ...item,
              price_list_group_values: [],
              pricing_group_setting: null
            };
          });
          return { ...prevData, items: injectValidationErrors(updatedItems) };
        });
      }
    }
  );
}
