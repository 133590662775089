import React, { useMemo, useEffect, useState } from "react"
import { Link } from "@reach/router"
import { Grid, ChevronLeft, X } from "react-feather"
import * as qs from "query-string"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useDataRequests } from "../../library/react"
import * as format from "../../library/format"
import { pricingUrlCurrency } from "../../library/routes"

import Input from "../../components/ui/Input/Input"
import Header from "../../components/Header"
import Select from "../../components/ui/Select/Select"
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline"
import Button from "../../components/ui/Button/Button"

import {
  getExchangeRates,
  requestCommodities,
  requestFilters,
  requestPriceListDetail,
  requestMatrixConfigurations,
} from "../../store/actions"
import { useGetCountries } from "../../requests/getCountries"
import { useGetPricingMultiple } from "../../requests/getPricing"

import PGTable from "./PGTable"
import FilterPanel from "./FilterPanel"
import CountryMatrixPicker from "./CountryMatrixPicker"

import * as s from "./index.module.css"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? '#d3d8e5' : 'transparent',
  ...draggableStyle,
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightgrey' : '',
});

export default function Pricing({
  state,
  dispatch,
  priceListId,
  location,
  navigate,
}) {
  const {
    session,
    commodities,
    exchangeRates,
    priceListsById,
    priceLists,
    filters,
  } = state.requests
  const matrixConfigurationForms = state?.matrixConfigurationForms?.localCopy

  const [mbrOptions, setMbrOptions] = useState([])
  const purchaseExchangeRates = exchangeRates.data?.purchaseRatesByCurrency
  const salesExchangeRates = exchangeRates.data?.saleRatesByCurrency
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [levels, setLevels] = useState([])

  useEffect(() => {
    if (salesExchangeRates) {
      setCurrencyOptions(
        Object.entries(Object.assign({'': 'Zvolte měnu'}, salesExchangeRates)).map(
          item => ({
            value: item[0],
            title: `${item[0]} ${item[1] !== 1 ? `(${item[1]})` : ''}`,
          })
        )
      );
    }
  }, [salesExchangeRates])

  const urlParams = useMemo(
    () =>
      qs.parse(location.search, {
        parseNumbers: false,
        parseBooleans: true,
        arrayFormat: "bracket"
      }),
    [location.search]
  )
  const [selectedCurrency, setSelectedCurrency] = useState(urlParams?.currency)
  const priceList = priceListsById[priceListId]?.data || {}
  const { finished, id } = priceList
  const {
    "difference[comparator]": diffCmp,
    "difference[value]": diffVal,
    "difference[x]": diffX,
    "difference[y]": diffY,
    matrix,
    page,
    limit,
    commodity = [],
    brand,
    category,
    gender,
    isColorful,
    size,
    "sort[value]": sortValue,
    "sort[x]": sortX,
    "sort[y]": sortY,
    "sort[direction]": sortDir,
  } = urlParams
  const planParams = {
    currency: void 0, // this doesn't do squat on API
    priceListId,
    limit,
    page,
    commodity,
    brand,
    category,
    gender,
    isColorful,
    size,
    "sort[value]": sortValue,
    "sort[x]": sortX,
    "sort[y]": sortY,
    "sort[direction]": sortDir,
    matrix,
    "difference[comparator]": diffCmp,
    "difference[value]": diffVal,
    "difference[x]": diffX,
    "difference[y]": diffY,
  }

  const getPricingParam = useMemo(() => {
    return levels.map(level => ({ ...planParams, countryCode: level.country }))
  }, [levels, planParams])
  const pricingColl = useGetPricingMultiple(getPricingParam)

  useDataRequests({ state, dispatch }, [
    () => requestMatrixConfigurations({ countryCode: '$__ALL__$' }),
    () => requestCommodities(),
    () => requestPriceListDetail(priceListId),
    () => requestFilters({ priceListId, countryCode: 'CZ' })
  ])
  const { data: countries } = useGetCountries()
  const loading = pricingColl.isFetching || !countries

  useEffect(() => dispatch(getExchangeRates({ id, finished })), [
    dispatch,
    id,
    finished
  ])

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      levels,
      result.source.index,
      result.destination.index
    );
    setLevels(items);
  }

  const isLocked = priceList.locked || priceList.finished;
  return (
    <div>
      <Header user={session.data?.user} priceLists={priceLists.data} />
      <main className={s.fixedSize}>
        <ScreenHeadline
          title={loading ? "Načítá se" : "Srovnání v jedné měně"}
          subtitle={priceList?.title ? `${priceList?.title} (${format.date(priceList?.from)} - ${format.date(priceList?.to)})` : ' '}
          loading={loading}
          leftActionButton={
            <Link to={`/price-list/${priceListId}`} className={s.backButton}>
              <ChevronLeft />
              Seznam ceníků
            </Link>
          }
        />
        <div className="h-4" />
        <div>
          <div>
            <Select
              options={currencyOptions}
              value={selectedCurrency}
              onChange= {e => {
                setSelectedCurrency(e.target.value);
                navigate(pricingUrlCurrency(
                  priceListId,
                  null,
                  {
                    ...urlParams,
                    currency: e.target.value,
                  }
                ))
              }}
            />
            <div className="h-4" />
          </div>
          <div>
            <div className="mb-4">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="levels">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {levels.map((level, index) => {
                        const countryName = countries?.data?.find(c => c.code === level.country)?.name
                        const countryVal = countryName ? `${countryName} (${level.country})` : level.country
                        const key = `${level.country}-${level.matrix}`
                        return (
                          <Draggable key={key} draggableId={key} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                key={index}
                                className="flex flex-row items-center mb-1"
                              >
                                <div>
                                  <Grid style={{ width: '20px', marginRight: '.5rem' }} />
                                </div>
                                <div>
                                  <Input
                                    disabled
                                    value={countryVal}
                                  />
                                </div>
                                <div>
                                  <Input
                                    disabled
                                    value={level.matrix}
                                  />
                                </div>
                                <div>
                                  <Button
                                    bgColor="#888"
                                    onClick={() => setLevels([...levels.slice(0, index), ...levels.slice(index + 1, levels.length)])}
                                    height='2.75rem'
                                  >
                                    <X style={{ display: 'block', minWidth: '24px' }} />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <CountryMatrixPicker
              countries={countries}
              matrixConfigurations={matrixConfigurationForms}
              onSubmit={conf => {
                const newLevels = (conf.matrix?.length ? conf.matrix : []).map(matrix => ({
                  country: conf.country,
                  currency: countries.data.find(c => c.code === conf.country).currency,
                  matrix: matrix.value,
                }))
                const filteredNewLevels = newLevels.filter(newLevel => {
                  return !levels.find(level => level.matrix === newLevel.matrix && level.country === newLevel.country)
                })
                setLevels([
                  ...levels,
                  ...filteredNewLevels
                ])
              }}
            />
          </div>
        </div>
        <div className="h-4" />
        <FilterPanel
          priceListId={priceListId}
          commoditiesData={commodities?.data}
          currencyPickerMode={true}
          params={urlParams}
          navigate={navigate}
          dispatch={dispatch}
          mbrOptions={mbrOptions}
          pricingColl={pricingColl}
          matrixConfigurationsSrc={matrixConfigurationForms}
          filters={filters}
        />
        <PGTable
          pricingColl={pricingColl}
          selectedCols={levels}
          loading={loading}
          currencyPickerMode={true}
          matrixConfigurationsSrc={matrixConfigurationForms}
          priceListId={priceListId}
          dispatch={dispatch}
          params={urlParams}
          navigate={navigate}
          purchaseExchangeRates={purchaseExchangeRates}
          salesExchangeRates={salesExchangeRates}
          selectedCurrency={selectedCurrency}
          isLocked={isLocked}
          setMbrOptions={setMbrOptions}
        />
      </main>
    </div>
  );
}
