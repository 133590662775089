import React, { useMemo, useEffect, useState } from "react";
import * as qs from "query-string";
import { Link } from "@reach/router";
import { ChevronLeft } from "react-feather";

import Header from "../../components/Header";
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline";

import { useGetCountries } from "../../requests/getCountries";
import { useGetPricing } from "../../requests/getPricing";
import { useDataRequests } from "../../library/react";
import * as format from "../../library/format";
import { pricingUrl } from "../../library/routes";
import {
  getExchangeRates,
  requestCommodities,
  requestFilters,
  requestPriceListDetail,
  requestMatrixConfigurations
} from "../../store/actions";

import FilterPanel from "./FilterPanel";
import PlanOverviewPanel from "./PlanOverviewPanel";
import Currencies from "./Currencies";
import PGTable from "./PGTable";

import * as s from "./index.module.css";

export default function Pricing({
  state,
  dispatch,
  priceListId,
  countryCode,
  location,
  navigate
}) {
  const {
    session,
    matrixConfigurations,
    commodities,
    exchangeRates,
    priceListsById,
    priceLists,
    filters
  } = state.requests;
  const [mbrOptions, setMbrOptions] = useState([]);
  const purchaseExchangeRates = exchangeRates.data?.purchaseRatesByCurrency;
  const salesExchangeRates = exchangeRates.data?.saleRatesByCurrency;

  const urlParams = useMemo(
    () =>
      qs.parse(location.search, {
        parseNumbers: false,
        parseBooleans: true,
        arrayFormat: "bracket"
      }),
    [location.search]
  );
  const priceList = priceListsById[priceListId]?.data || {};
  const { finished, id } = priceList;
  const {
    "difference[comparator]": diffCmp,
    "difference[value]": diffVal,
    "difference[x]": diffX,
    "difference[y]": diffY,
    matrix,
    page,
    limit,
    currency,
    commodity = [],
    brand,
    category,
    gender,
    isColorful,
    size,
    "sort[value]": sortValue,
    "sort[x]": sortX,
    "sort[y]": sortY,
    "sort[direction]": sortDir,
    showPlanOverview
  } = urlParams;
  const planParams = {
    priceListId,
    countryCode,
    limit,
    page,
    commodity,
    currency,
    brand,
    category,
    gender,
    isColorful,
    size,
    "sort[value]": sortValue,
    "sort[x]": sortX,
    "sort[y]": sortY,
    "sort[direction]": sortDir,
    matrix,
    "difference[comparator]": diffCmp,
    "difference[value]": diffVal,
    "difference[x]": diffX,
    "difference[y]": diffY
  };
  const pricingColl = useGetPricing(planParams);

  useDataRequests({ state, dispatch }, [
    () => requestMatrixConfigurations({ countryCode }),
    () => requestCommodities(),
    () => requestPriceListDetail(priceListId),
    () => requestFilters({ priceListId, countryCode })
  ]);
  const { data: countries } = useGetCountries();
  const loading = pricingColl.isFetching || !countries;

  useEffect(() => dispatch(getExchangeRates({ id, countryCode, finished })), [
    dispatch,
    id,
    countryCode,
    finished
  ]);

  const country = useMemo(
    () => countries?.data?.find(c => c.code === countryCode) || {},
    [countries, countryCode]
  );
  const countryName = country.name;

  const isLocked = priceList.locked || priceList.finished;

  return (
    <div>
      <Header user={session.data?.user} priceLists={priceLists.data} />
      <main className={s.fixedSize}>
        <ScreenHeadline
          title={countryName || "Načítá se"}
          subtitle={priceList?.title ? `${priceList?.title} (${format.date(priceList?.from)} - ${format.date(priceList?.to)})` : ' '}
          loading={!countryName}
          leftActionButton={
            <Link to={`/price-list/${priceListId}`} className={s.backButton}>
              <ChevronLeft />
              Seznam ceníků
            </Link>
          }
        />
        <div className="h-4" />
        <Currencies
          countryCode={countryCode}
          navigate={navigate}
          params={urlParams}
          priceListId={priceListId}
          purchaseExchangeRates={purchaseExchangeRates}
          saleExchangeRate={salesExchangeRates?.[country?.currency]}
          currency={country?.currency}
          urlCreator={pricingUrl}
        />
        <PlanOverviewPanel
          priceListId={priceListId}
          country={country}
          countryCode={countryCode}
          pricingColl={pricingColl}
          purchaseExchangeRates={purchaseExchangeRates}
          showPlanOverview={showPlanOverview}
          urlParams={urlParams}
          salesExchangeRates={salesExchangeRates}
          navigate={navigate}
          modelExRate={urlParams.modelExRate}
        />
        <div className="h-4" />
        <FilterPanel
          priceListId={priceListId}
          countryCode={countryCode}
          commoditiesData={commodities?.data}
          params={urlParams}
          navigate={navigate}
          dispatch={dispatch}
          mbrOptions={mbrOptions}
          pricingColl={pricingColl}
          matrixConfigurationsData={matrixConfigurations[countryCode]?.data}
          filters={filters}
        />
        <PGTable
          pricingColl={[pricingColl]}
          loading={loading}
          matrixConfigurations={matrixConfigurations[countryCode]}
          priceListId={priceListId}
          dispatch={dispatch}
          params={urlParams}
          navigate={navigate}
          country={country}
          purchaseExchangeRates={purchaseExchangeRates}
          salesExchangeRates={salesExchangeRates}
          isLocked={isLocked}
          setMbrOptions={setMbrOptions}
        />
      </main>
    </div>
  );
}
