import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { configureStore } from "./store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initializeApplication, ping } from "./store/actions";
import createRollbarInstance from "./library/rollbar";

const rollbar = createRollbarInstance();

const [appElement, modalElement] = ["root", "modal-root"].map(id =>
  document.getElementById(id)
);

const store = configureStore();
const { dispatch, subscribe } = store;

store.subscribe(state => {
  renderApplication({ state, dispatch, subscribe, rollbar });
});

store.dispatch(initializeApplication()); // first render

setTimeout(() => {
  store.dispatch(ping()); // test
}, 200);

function renderApplication(props) {
  ReactDOM.render(<App modalElement={modalElement} {...props} />, appElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
