import { useQuery } from "react-query";
import { fetch } from "../library/fetch";
import * as qs from "query-string";

export function useGetPricingKPI({
  enabled,
  priceListId,
  countryCode,
  ...params
}) {
  const q = qs.stringify(params, {
    arrayFormat: "bracket"
  });
  const query = {
    method: "GET",
    url: `/pricing-list/${priceListId}/country/${countryCode}/pricing/kpi?${q}`,
    enabled,
  };
  return useQuery([query], () => fetch(query), { enabled });
}
