import React from "react";
import Text from "../../components/ui/Text/Text";

import * as s from "./PGTable.module.css";

export default function SalesExchangeRateInput({
  currency,
  saleExchangeRate,
  disabled,
  currentSaleRate,
  onChange,
}) {
  return (
    <div className="flex">
      <div className="self-center">Prodejní:&nbsp;</div>
      <div className="flex mr-4">
        <div className={s.exchangeRate}>
          <Text size=".75rem" className={s.exchangeRateLabel}>
            {currency}
          </Text>
          <Text size=".75rem" className={s.exchangeRateValue} weight="600">
            {saleExchangeRate}
          </Text>
          <input
            disabled={disabled}
            value={currentSaleRate}
            className={s.inputNumber}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}