import { useQuery, useQueries } from "react-query";
import { fetch } from "../library/fetch";
import * as qs from "query-string";
import { injectValidationErrors } from "../store/reducers/validations";

function select(resp) {
  const { headers, data } = resp;
  return {
    items: injectValidationErrors(data),
    validations: {},
    count: Number(headers["x-count"]),
    page: Number(headers["x-page"]),
    limit: Number(headers["x-items-per-page"])
  };
}

export function pricingQueryKey({
  priceListId,
  countryCode,
  limit = 100,
  page = 1,
  commodity,
  matrix,
  "sort[value]": sortValue,
  "sort[x]": sortX,
  "sort[y]": sortY,
  "sort[direction]": sortDirection,
  ...rest
}) {

  const sortProps = !(sortValue && sortX && sortY) ? {} : {
    "sort[direction]": sortDirection || 'ASC',
    "sort[x]": sortX,
    "sort[y]": sortY,
    "sort[value]": sortValue,
  };
  
  const params = qs.stringify(
    { limit, page, commodity, matrix, ...rest, ...sortProps, showPlanOverview: undefined, showDiff: undefined, showDiffPercent: undefined },
    {
      arrayFormat: "bracket"
    }
  );
  return [
    {
      method: "GET",
      url: `/pricing-list/${priceListId}/country/${countryCode}/pricing?${params}`,
      enabled: !!countryCode,
    }
  ];
}

export function useGetPricing(params) {
  const query = pricingQueryKey(params);
  return useQuery(query, () => fetch(query[0]).then(select)); // use `select` in `then` to cache transformed data for mutations
}

export function useGetPricingMultiple(paramsArr) {
  // return useQuery(query, () => fetch(query[0]).then(select)); // use `select` in `then` to cache transformed data for mutations
  const queries = paramsArr.reduce((acc, params) => {
    if (params.countryCode) {
      const query = pricingQueryKey(params);
      acc.push({
        queryKey: query,
        queryFn: () => fetch(query[0]).then(select),
      })
    }
    return acc
  }, [])
  return useQueries(//{
    queries
  )
  //})
}
