import React, { useState, useMemo } from "react";
import Button from "../../components/ui/Button/Button";
import {
  // Filter,
  X,
  ChevronUp,
  ChevronDown
} from "react-feather";
import Text from "../../components/ui/Text/Text";
import { pricingUrl as pricingUrlClassic, pricingUrlCurrency } from "../../library/routes";
import Select from "react-select3";
import debounce from "../../library/debounce";
import classNames from "classnames";
import { isEmpty } from "../../library/object";
import { default as OurSelect } from "../../components/ui/Select/Select";
import { levelLimit, matrixXToLevel } from "../../library/matrix";

import * as s from "./FilterPanel.module.css";

function assembleMbrFilter(equalsSelected, selectedOp, value, selectedLevel) {
  let op = null;
  if (selectedOp === "<") {
    op = equalsSelected ? "lte" : "lt";
  } else if (selectedOp === ">") {
    op = equalsSelected ? "gte" : "gt";
  } else if (equalsSelected) {
    op = "eq";
  }
  return (
    op &&
    value !== "" && {
      "difference[comparator]": op,
      "difference[value]": value,
      "difference[x]": selectedLevel.x,
      "difference[y]": selectedLevel.y
    }
  );
}

const mbrFilter = (
  navigate,
  priceListId,
  countryCode,
  equalsSelected,
  selectedOp,
  value,
  selectedLevel,
  otherFilters,
  {
    pricingUrl
  }
) => {
  const assembledMbrFilter = assembleMbrFilter(
    equalsSelected,
    selectedOp,
    value,
    selectedLevel
  );
  if (assembledMbrFilter) {
    navigate(
      pricingUrl(priceListId, countryCode, {
        ...assembledMbrFilter,
        ...otherFilters,
        page: 1
      })
    );
  }
};

const FilterItem = ({ cb, name, options, params, title, value }) => {
  if (!options?.length) return null;

  return (
    <div className='mr-10 flex items-center'>
      <Text weight="600" className="mr-2">
        {title}
      </Text>
      <OurSelect
        containerClass={`${s.levelSelect} ${s.levelSelectWide}`}
        value={`${value}`}
        options={[
          { value: ALL_VALUE, title: 'vše' },
          ...options.map(item => ({
            value: item.code,
            title: item.name
          }))
        ]}
        onChange={({ target: { value } }) => {
          const newParams = { ...params, [name]: value, page: 1 };
          if (value === ALL_VALUE) delete newParams[name];
          cb && cb(newParams);
        }}
      />
    </div>
  );
};

const delayedMbrFilter = debounce(
  (
    navigate,
    priceListId,
    countryCode,
    equalsSelected,
    selectedOp,
    value,
    selectedLevel,
    otherFilters,
    {
      pricingUrl
    }
  ) => {
    mbrFilter(
      navigate,
      priceListId,
      countryCode,
      equalsSelected,
      selectedOp,
      value,
      selectedLevel,
      otherFilters,
      {
        pricingUrl
      }
    );
  },
  600 // 1s => .6s speed up
);

function disassembleFilter(comparator) {
  const filter = { equalsSelected: false, selectedOp: null };
  switch (comparator) {
    case "eq":
      return { equalsSelected: true, selectedOp: null };
    case "lt":
      return { equalsSelected: false, selectedOp: "<" };
    case "lte":
      return { equalsSelected: true, selectedOp: "<" };
    case "gt":
      return { equalsSelected: false, selectedOp: ">" };
    case "gte":
      return { equalsSelected: true, selectedOp: ">" };
    default:
      return filter;
  }
}

function toOption(x, y) {
  return {
    title: levelLimit(matrixXToLevel[y], x),
    value: JSON.stringify({ x, y })
  };
}

const ALL_VALUE = "all";

function MatrixFilter({
  configurations,
  navigate,
  params,
  priceListId,
  countryCode,
  pricingUrl
}) {
  const options = configurations?.map(({ name, id }) => ({
    title: name,
    value: id
  }));
  if (options) {
    options.unshift({ title: OurSelect.NO_VALUE, value: "null" });
    options.unshift({ title: "vše", value: ALL_VALUE });
  }

  return (
    <div className={s.matrixFilter}>
      <Text weight="600" className="mr-2">
        KONF.
      </Text>
      <OurSelect
        containerClass={s.levelSelect}
        value={params.matrix || ALL_VALUE}
        options={options}
        onChange={({ target: { value } }) => {
          const newParams = { ...params, matrix: value };
          if (value === ALL_VALUE) delete newParams.matrix;
          navigate(pricingUrl(priceListId, countryCode, newParams));
        }}
      />
    </div>
  );
}

function getFilterParams(urlParams) {
  return {
    commodity: urlParams.commodity,
    gender: urlParams.gender,
    size: urlParams.size,
    isColorful: urlParams.isColorful,
    brand: urlParams.brand,
    category: urlParams.category,
    'sort[value]': urlParams['sort[value]'],
    'sort[direction]': urlParams['sort[direction]'],
    'sort[x]': urlParams['sort[x]'],
    'sort[y]': urlParams['sort[y]']
  };
}

function MbrFilter({
  priceListId,
  countryCode,
  navigate,
  urlParams,
  leadingValues,
  pricingUrl
}) {
  const filterFromUrl = disassembleFilter(urlParams["difference[comparator]"]);
  const [equalsSelected, setEqualsSelected] = useState(
    filterFromUrl.equalsSelected
  );
  const [selectedOp, setSelectedOp] = useState(filterFromUrl.selectedOp);
  const [value, setValue] = useState(urlParams["difference[value]"] || "");

  // currencyPickerMode may return non-unique levels
  const uniqueLeadingValues = [...new Map(
    leadingValues.map(item => [`${item.y}${item.x}`, item])
  ).values()]

  const levels = (uniqueLeadingValues || []).map(({ x, y }) => toOption(x, y));

  const firstLevel = leadingValues?.[0] || { x: 1, y: 1 };
  const [selectedLevel, setSelectedLevel] = useState({
    x: parseInt(urlParams["difference[x]"] || firstLevel.x, 10),
    y: parseInt(urlParams["difference[y]"] || firstLevel.y, 10)
  });

  return (
    <div className={s.mbrFilter}>
      <Text weight="600" className="mr-2">
        MBR
      </Text>
      <OurSelect
        containerClass={s.levelSelect}
        value={toOption(selectedLevel.x, selectedLevel.y).value}
        options={levels}
        onChange={e => {
          const newSelectedLevel = JSON.parse(e.target.value);
          setSelectedLevel(newSelectedLevel);
          delayedMbrFilter(
            navigate,
            priceListId,
            countryCode,
            equalsSelected,
            selectedOp,
            value,
            newSelectedLevel,
            getFilterParams(urlParams),
            {
              pricingUrl
            }
          );
        }}
      />
      <button
        className={classNames(s.mbrOpBtn, {
          [s.mbrOpBtnSelected]: selectedOp === "<"
        })}
        onClick={_ => {
          const newOp = selectedOp !== "<" ? "<" : null;
          setSelectedOp(newOp);
          delayedMbrFilter(
            navigate,
            priceListId,
            countryCode,
            equalsSelected,
            newOp,
            value,
            selectedLevel,
            getFilterParams(urlParams),
            {
              pricingUrl
            }
          );
        }}
      >
        {"<"}
      </button>
      <button
        className={classNames(s.mbrOpBtn, {
          [s.mbrOpBtnSelected]: equalsSelected
        })}
        onClick={_ => {
          const newExS = !equalsSelected;
          setEqualsSelected(newExS);
          delayedMbrFilter(
            navigate,
            priceListId,
            countryCode,
            newExS,
            selectedOp,
            value,
            selectedLevel,
            getFilterParams(urlParams),
            {
              pricingUrl
            }
          );
        }}
      >
        {"="}
      </button>
      <button
        className={classNames(s.mbrOpBtn, "mr-2", {
          [s.mbrOpBtnSelected]: selectedOp === ">"
        })}
        onClick={_ => {
          const newOp = selectedOp !== ">" ? ">" : null;
          setSelectedOp(newOp);
          delayedMbrFilter(
            navigate,
            priceListId,
            countryCode,
            equalsSelected,
            newOp,
            value,
            selectedLevel,
            getFilterParams(urlParams),
            {
              pricingUrl
            }
          );
        }}
      >
        {">"}
      </button>
      <div className={s.mbrPrcWrap}>
        <input
          type="number"
          className={s.mbrPrcInput}
          value={value}
          onChange={e => {
            const newValue = e.target.value;
            setValue(newValue);
            delayedMbrFilter(
              navigate,
              priceListId,
              countryCode,
              equalsSelected,
              selectedOp,
              newValue,
              selectedLevel,
              getFilterParams(urlParams),
              {
                pricingUrl
              }
            );
          }}
        />{" "}
        %
      </div>
    </div>
  );
}

export const getCustomInputStyles = ({ control } = {}) => ({
  clearIndicator: () => ({
    display: "none",
  }),
  valueContainer: base => ({
    ...base,
    maxHeight: "2rem",
    overflow: "auto",
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    ...control,
    borderRadius: '0',
  }),
});

export default function FilterPanel({
  priceListId,
  countryCode,
  commoditiesData,
  currencyPickerMode,
  filters,
  params,
  pricingColl,
  navigate,
  // leadingValues,
  mbrOptions,
  matrixConfigurationsData
}) {
  const pricingUrl = currencyPickerMode ? pricingUrlCurrency : pricingUrlClassic;
  const anyAdvancedFilterSet = Object.keys(params || {}).find(param => [
    'gender',
    'brand',
    'category',
    'isColorful',
    'size',
    'sort[direction]',
    'sort[x]',
    'sort[y]',
    'sort[value]'
  ].includes(param));
  const [advancedFiltersShown, setAdvancedFiltersShown] = useState(anyAdvancedFilterSet);
  const commodities = useMemo(
    () =>
      commoditiesData?.map(com => ({
        label: `${com.code} ${com.name}`,
        value: com.code
      })),
    [commoditiesData]
  );

  const sortLevel = `{"x":${params?.['sort[x]']},"y":${params?.['sort[y]']}}`

  const selectStyles = useMemo(() => getCustomInputStyles(), []);

  return (
    <>
      <section className={s.filterPanel}>
        <div className="flex">
          <Button
            plain
            size='.8rem'
            onClick={() => setAdvancedFiltersShown(!advancedFiltersShown)}
            title={`${advancedFiltersShown ? 'Skrýt' : 'Zobrazit'} pokročilé filtry`}
          >
            <div className="flex items-center mr-10">
              <div className={s.icon}>
                {/*<Filter size="14" />*/}
                {advancedFiltersShown
                  ? <ChevronUp size="14" />
                  : <ChevronDown size="14" />
                }
              </div>
              <Text size=".875rem" color="#445E9C" weight="600">
                Filtrovat...
              </Text>
            </div>
          </Button>
          <Select
            isMulti="true"
            styles={selectStyles}
            placeholder="Komodita"
            className={s.commoditySelect}
            options={commodities}
            value={(params.commodity || []).map(c => ({ label: c, value: c }))}
            onChange={selected => {
              const q = pricingUrl(priceListId, countryCode, {
                ...params,
                commodity: (selected || []).map(s => s.value),
                page: 1
              })
              navigate(
                q
              );
            }}
          />
          {!currencyPickerMode && (
            <MatrixFilter
              configurations={matrixConfigurationsData}
              pricingUrl={pricingUrl}
              {...{ params, navigate, priceListId, countryCode }}
            />
          )}
          <MbrFilter
            key={params["difference[value]"]}
            navigate={navigate}
            priceListId={priceListId}
            countryCode={countryCode}
            urlParams={params}
            pricingUrl={pricingUrl}
            leadingValues={mbrOptions}
          />
          {!isEmpty(params) && (
            <button
              onClick={() => navigate(pricingUrl(priceListId, countryCode))}
              className={`${s.clearFilter} ml-2 mr-2`}
            >
              Zrušit vše <X className={s.clearFilterIcon} />
            </button>
          )}
        </div>
    {
      advancedFiltersShown && Object.keys(filters?.data || {}).length
        ? (
          <>
            <div className={s.filterHr} />
            <section className={s.innerFilterPanel}>
              <div className="flex">
                <div className="flex items-center mr-10">
                  <FilterItem
                    title='Značka'
                    name='brand'
                    value={params.brand}
                    options={filters.data?.brand}
                    params={params}
                    cb={newParams => navigate(pricingUrl(priceListId, countryCode, newParams))}
                  />
                  <FilterItem
                    title='Kategorie'
                    name='category'
                    value={params.category}
                    options={filters.data?.category}
                    params={params}
                    cb={newParams => navigate(pricingUrl(priceListId, countryCode, newParams))}
                  />
                  <FilterItem
                    title='Gender'
                    name='gender'
                    value={params.gender}
                    options={filters.data?.gender}
                    params={params}
                    cb={newParams => navigate(pricingUrl(priceListId, countryCode, newParams))}
                  />
                  <FilterItem
                    title='Barva'
                    name='isColorful'
                    value={params.isColorful}
                    options={filters.data?.isColorful}
                    params={params}
                    cb={newParams => navigate(pricingUrl(priceListId, countryCode, newParams))}
                  />
                  <FilterItem
                    title='Velikost'
                    name='size'
                    value={params.size}
                    options={filters.data?.size}
                    params={params}
                    cb={newParams => navigate(pricingUrl(priceListId, countryCode, newParams))}
                  />
                  <div className='mr-10 flex items-center'>
                    <Text weight="600" className="mr-2">
                      Řazení
                    </Text>
                    <OurSelect
                      containerClass={`${s.levelSelect} ${s.levelSelectWide}`}
                      value={params['sort[value]']}
                      options={[
                        { value: null, title: '' },
                        { value: 'margin', title: 'Marže' },
                        { value: 'price', title: 'Cena' },
                        { value: 'difference_absolute', title: 'Diff (abs.)' },
                        { value: 'difference_percentage', title: 'Diff (%)' }
                      ]}
                      onChange={({ target: { value } }) => {
                        const newParams = { ...params, 'sort[value]': value };
                        if (!value) delete newParams['sort[value]'];
                        navigate(pricingUrl(priceListId, countryCode, newParams));
                      }}
                    />
                    <OurSelect
                      containerClass={s.levelSelect}
                      value={sortLevel}
                      options={[
                        { value: null, title: '' },
                        ...(mbrOptions || []).map(({ x, y }) => toOption(x, y))
                      ]}
                      onChange={({ target: { value } }) => {
                        const parsedValue = value ? JSON.parse(value) : ''
                        const newParams = { ...params, 'sort[x]': parsedValue?.x, 'sort[y]': parsedValue?.y };
                        if (!value) {
                          delete newParams['sort[x]'];
                          delete newParams['sort[y]'];
                        }
                        navigate(pricingUrl(priceListId, countryCode, newParams));
                      }}
                    />
                    <OurSelect
                      containerClass={s.levelSelect}
                      value={params['sort[direction]'] || 'ASC'}
                      options={[
                        { value: 'ASC', title: '⇧' },
                        { value: 'DESC', title: '⇩' }
                      ]}
                      onChange={({ target: { value } }) => {
                        const newParams = { ...params, 'sort[direction]': value };
                        if (!value) delete newParams['sort[direction]'];
                        navigate(pricingUrl(priceListId, countryCode, newParams));
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : null
      }
      </section>
    </>
  );
  
}
