import React from "react";

const fallBackColors = {};

const getFallBackImg = colorCode => {
  return fallBackColors[colorCode];
};

export function ColorImg({ alt, className, src, color, width, height }) {
  const fallBackImg = getFallBackImg(color);
  const source =
    fallBackImg ||
    src ||
    `${process.env.REACT_APP_SHARED_IMAGES_URL}/color/base/${color}.jpg`;
  return (
    <img
      alt={alt || color}
      className={className}
      width={width}
      height={height}
      src={source}
    />
  );
}

export default ColorImg;
