import React, { useContext } from "react";
import * as s from "./index.module.css";
import ColorImg from "../../components/ui/ColorImg";
import classNames from "classnames";
import { ReadOnlyContext } from "../../App";

const NomenclatureItem = React.memo(function NomenclatureItem({
  nomenclature,
  iconLeft,
  iconRight
}) {
  const readOnly = useContext(ReadOnlyContext);
  let nomId = nomenclature.id;

  const arrow =
    !readOnly &&
    ((iconRight && iconRight(nomId)) || (iconLeft && iconLeft(nomId)));
  return (
    <tr className={s.nomenclatureItem}>
      <td className={s.colorCol}>
        <ColorImg color={nomenclature.color_code} width="24" height="24" />
      </td>
      <td className={s.nomSizeCol}>{nomenclature.size.name}</td>
      <td className={s.nomIdCol}>
        <div className="flex items-center">
          {nomId}
          {arrow}
        </div>
      </td>
    </tr>
  );
});

export const NomenclaturesList = React.memo(function NomenclaturesList({
  nomenclatures,
  loading,
  iconLeft,
  iconRight
}) {
  return (
    nomenclatures.length > 0 && (
      <table
        className={classNames(s.nomenclaturesList, {
          [s.nomenclaturesListLoading]: loading
        })}
      >
        <tbody>
          {nomenclatures.map(nom => (
            <NomenclatureItem
              key={nom.id}
              nomenclature={nom}
              iconLeft={iconLeft}
              iconRight={iconRight}
            />
          ))}
        </tbody>
      </table>
    )
  );
});

export default NomenclaturesList;
