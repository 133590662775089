import React, { useMemo, useEffect, useState } from "react";
import { Check } from "react-feather";
import { indexToChar } from "../Matrix/Configuration";

import ReactSelect from "react-select3";
import Select from "../../components/ui/Select/Select";
import Button from "../../components/ui/Button/Button";

import { getCustomInputStyles } from './FilterPanel';

export default function CountryMatrixPicker({ countries, matrixConfigurations, onSubmit }) {
  const [country, setCountry] = useState('null')
  const [matrix, setMatrix] = useState('null')

  const selectStyles = useMemo(() => getCustomInputStyles({ control: { height: '2.75rem' } }), []);
  const countryOpts = useMemo(() => {
    const cOpts = countries?.data.map(c => ({ value: c.code, title: c.name}))
    return [{ value: 'null', title: 'Zvolte zemi' }, ...(cOpts || [])]
  }, [countries])
  const matrixOpts = useMemo(() => {
    if (country) {
      const configurations = matrixConfigurations?.[country]
      const { w, h } = (configurations || []).reduce((acc, conf) => {
        if (conf.matrix?.length > acc.h) acc.h = conf.matrix.length
        if (conf.matrix?.[0].length > acc.w) acc.w = conf.matrix[0].length
        return acc
      }, { w: 1, h: 1 })

      const options = []
      for (let i = 1;i <= h;i++) {
        for (let j = 1;j <= w;j++) {
          const value = `${indexToChar(i)}${j}`
          // options.push({ value, title: value })
          options.push({ value, label: value })
        }
      }
      // const options = configurations?.map(({ name, id }) => ({
      //   title: name,
      //   value: id
      // }));
      // if (options) {
        // options.unshift({ title: 'Zvolte hodnotu'/*OurSelect.NO_VALUE*/, value: "null" });
        // options.unshift({ title: "vše", value: ALL_VALUE });
      // }
      return options
    }
    return []
  }, [country, matrixConfigurations])
  useEffect(() => {
    if (!country) {
      setMatrix();
    }
  }, [country])
  const submit = payload => {
    onSubmit(payload)
    setMatrix('null')
    setCountry('null')
  }

  if (!(countries?.data?.length && matrixConfigurations)) return null;

  return (
    <div className="flex">
      <div className="flex-1">
        <Select
          options={countryOpts}
          value={country}
          onChange={e => setCountry(e.target.value)}
        />
      </div>
      {/*
      <div className="flex-1">
        <Select
          options={matrixOpts}
          value={matrix}
          onChange={e => setMatrix(e.target.value)}
          disabled={!country}
        />
      </div>
      */}
      <div className="flex-1">
        {country === 'null' ? (
          <Select disabled />
        ) : (
          <ReactSelect
            isMulti
            styles={selectStyles}
            options={matrixOpts}
            value={matrix}
            onChange={selected => setMatrix(selected)}
          />
        )}
      </div>
      <div>
        <Button
          onClick={() => submit({ country, matrix })}
          width='60px'
          height='2.75rem'
        >
          <Check style={{ display: 'block', minWidth: '24px' }} />
        </Button>
      </div>
    </div>
  )
}