import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import idx from "idx";
import { getExchangeRates, updateExchangeRate } from "../../store/actions";
import styles from "./ExchangeRates.module.css";
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline";
import { ReadOnlyContext } from "../../App";

const mapStateToProps = state => {
  return {
    session: state.requests.session,
    exchangeRates: state.requests.exchangeRates
  };
};

function RateItem({ rate, currency, type, dispatch, readOnly }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className={styles.rateItem}>
      <span className="font-semibold">{currency}</span>
      <span onClick={() => !readOnly && setIsOpen(true)}>
        {isOpen && currency !== "CZK" ? (
          <input
            autoFocus
            defaultValue={rate}
            className={styles.rateInput}
            onBlur={e => {
              if (Number(e.target.value) !== rate) {
                dispatch(
                  updateExchangeRate({ currency, rate: e.target.value, type })
                );
              }
              setIsOpen(false);
            }}
            onKeyDown={e => {
              if (e.key === "Enter" || e.key === "Escape") {
                e.target.blur();
              }
            }}
          />
        ) : (
          rate
        )}
      </span>
    </li>
  );
}

function RatesList({ exchangeRates, dispatch, type, readOnly }) {
  return Object.keys(exchangeRates).length !== 0 ? (
    <ul className={styles.ratesList}>
      {Object.entries(exchangeRates).map(([currency, exchangeRate]) => (
        <RateItem
          key={currency}
          currency={currency}
          rate={exchangeRate}
          type={type}
          dispatch={dispatch}
          readOnly={readOnly}
        />
      ))}
    </ul>
  ) : (
    "Žádné kurzy"
  );
}

export default function ExchangeRates({ state, dispatch }) {
  const { session, exchangeRates } = mapStateToProps(state);
  const readOnly = useContext(ReadOnlyContext);

  useEffect(() => {
    dispatch(getExchangeRates());
  }, [dispatch]);

  return (
    <>
      <Header user={idx(session, _ => _.data.user)} />
      <ScreenHeadline title="Kurzy měny" />
      <main className={styles.content}>
        {exchangeRates.data && exchangeRates.pending === 0 ? (
          <>
            <h2 className={styles.subTitle}>Nákupní kurzy</h2>
            <RatesList
              dispatch={dispatch}
              exchangeRates={exchangeRates.data.purchase}
              type="PURCHASE"
              readOnly={readOnly}
            />

            <h2 className={styles.subTitle}>Prodejní kurzy</h2>
            <RatesList
              dispatch={dispatch}
              exchangeRates={exchangeRates.data.sales}
              type="SALES"
              readOnly={readOnly}
            />
          </>
        ) : (
          "Načítá se..."
        )}
      </main>
    </>
  );
}
