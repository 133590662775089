import React, { useState, useEffect, useMemo, useRef } from "react";
import Header from "../../components/Header";
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline";
import {
  requestNomenclaturesForPricingGroup,
  requestPricingGroups,
  changeNomenclaturesForPricingGroupSearch
} from "../../store/actions";
import ColumnHeadline from "../../components/ui/ColumnHeadline/ColumnHeadline";
import { Link } from "@reach/router";
import * as s from "./index.module.css";
import Text from "../../components/ui/Text/Text";
import NomenclaturesList from "./NomenclaturesList";
import { Search } from "react-feather";
import debounce from "../../library/debounce";
import Input from "../../components/ui/Input/Input";
import { useEffectOnce } from "../../library/hooks";
import * as qs from "query-string";

const searchPricingGroups = debounce(
  (dispatch, search) => dispatch(requestPricingGroups({ search })),
  250
);

const searchNomenclaturesForPricingGroup = debounce(
  (dispatch, pricingGroupId, search) =>
    dispatch(
      requestNomenclaturesForPricingGroup({
        pricingGroupId,
        search
      })
    ),
  250
);

const PricingGroupsList = React.memo(function PricingGroupsList({
  dispatch,
  pricingGroup,
  pricingGroups,
  loading,
  pricingGroupSearch = ""
}) {
  const [search, setSearch] = useState(pricingGroupSearch);
  const skipSearchLoad = useRef(pricingGroupSearch === "");

  useEffectOnce(() => {
    skipSearchLoad.current && dispatch(requestPricingGroups());
  });

  useEffect(() => {
    !skipSearchLoad.current && searchPricingGroups(dispatch, search);
    skipSearchLoad.current = false;
  }, [dispatch, search]);

  return (
    <section>
      <ColumnHeadline title="Ceníková skupina" loading={loading} />
      <div className={s.stickyTop}>
        <Input
          type="search"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="ceníková skupina..."
          icon={<Search className={s.searchIcon} />}
        />
      </div>
      <div className={s.scrollableCommoditiesColumn}>
        {pricingGroups &&
          pricingGroups.map(pg => (
            <Link
              to={`/pricing-groups/${pg.id}?pricingGroupSearch=${search}`}
              key={pg.id}
              className="no-underline text-black"
            >
              <div
                className={`${
                  pg.id === pricingGroup.id ? s.selectedCard : s.card
                } px-4 py-5 pr-0 shadow my-3 cursor-pointer flex flex-space-between`}
              >
                <Text weight="600" size="0.875rem" nolink className='flex-1'>
                  {pg.title}
                </Text>
              </div>
            </Link>
          ))}
      </div>
    </section>
  );
});

const NomenclaturesForPricingGroup = React.memo(
  function NomenclaturesForPricingGroup({
    dispatch,
    pricingGroup,
    nomenclatures,
    loading,
    nomenclaturesForPricingGroupSearch,
    unassignedNomenclaturesSearch
  }) {
    const skipSearchLoad = useRef(true);

    useEffectOnce(() => {
      pricingGroup.id &&
        dispatch(
          requestNomenclaturesForPricingGroup({
            pricingGroupId: pricingGroup.id,
            search: nomenclaturesForPricingGroupSearch
          })
        );
    });

    useEffect(() => {
      !skipSearchLoad.current &&
        searchNomenclaturesForPricingGroup(
          dispatch,
          pricingGroup.id,
          nomenclaturesForPricingGroupSearch
        );
      skipSearchLoad.current = false;
    }, [dispatch, pricingGroup.id, nomenclaturesForPricingGroupSearch]);

    return (
      <section>
        <ColumnHeadline
          title={(pricingGroup && pricingGroup.title) || ""}
          loading={!(pricingGroup && pricingGroup.title)}
        />
        <div className={`${s.nomenclaturesColumn} ${s.scrollableNomsColumn}`}>
          <div className={s.searchContainer}>
            <Input
              type="search"
              value={nomenclaturesForPricingGroupSearch}
              onChange={e =>
                dispatch(
                  changeNomenclaturesForPricingGroupSearch(e.target.value)
                )
              }
              placeholder="číslo nomenklatury..."
              icon={<Search className={s.searchIcon} />}
            />
          </div>
          <NomenclaturesList
            nomenclatures={nomenclatures}
            loading={loading}
          />
        </div>
      </section>
    );
  }
);

const mapStateToProps = state => {
  return {
    session: state.requests.session,
    pricingGroups: state.requests.pricingGroups.data,
    nomenclaturesForPricingGroup:
      state.requests.nomenclaturesForPricingGroup.data,
    unassignedNomenclatures: state.requests.unassignedNomenclatures.data,
    unassignedNomenclaturesSearch:
      state.pricingGroups.unassignedNomenclaturesSearch,
    nomenclaturesForPricingGroupSearch:
      state.pricingGroups.nomenclaturesForPricingGroupSearch,
    pricingGroupsLoading: state.requests.pricingGroups.pending > 0,
    nomenclaturesLoading:
      state.requests.nomenclaturesForPricingGroup.pending > 0,
    unassignedNomenclaturesLoading:
      state.requests.unassignedNomenclatures.pending > 0
  };
};

export default function PricingGroups({
  state,
  dispatch,
  pricingGroupId,
  location
}) {
  const {
    session,
    pricingGroups,
    pricingGroupsLoading,
    nomenclaturesForPricingGroup,
    nomenclaturesLoading,
    nomenclaturesForPricingGroupSearch
  } = mapStateToProps(state);
  const pricingGroupIdInt = Number(pricingGroupId);
  const { pricingGroupSearch } = useMemo(() => qs.parse(location.search), [
    location.search
  ]);

  const pricingGroup = useMemo(
    () =>
      (pricingGroups &&
        (pricingGroups.find(pg => pg.id === pricingGroupIdInt) ||
          pricingGroups[0])) ||
      {},
    [pricingGroups, pricingGroupIdInt]
  );

  return (
    <div>
      <Header
        user={session?.data?.user}
        priceLists={state.requests.priceLists.data}
      />
      <main style={{ maxWidth: "90rem", margin: "0 auto" }}>
        <div className="mx-6">
          <ScreenHeadline
            title="NASTAVENÍ CENÍKOVÝCH SKUPIN"
            loading={pricingGroupsLoading}
          />
        </div>
        <div className={s.mainGrid}>
          <PricingGroupsList
            pricingGroup={pricingGroup}
            pricingGroups={pricingGroups}
            loading={pricingGroupsLoading}
            dispatch={dispatch}
            pricingGroupSearch={pricingGroupSearch}
          />
          <NomenclaturesForPricingGroup
            pricingGroup={pricingGroup}
            nomenclatures={nomenclaturesForPricingGroup}
            loading={nomenclaturesLoading}
            dispatch={dispatch}
            nomenclaturesForPricingGroupSearch={
              nomenclaturesForPricingGroupSearch
            }
          />
        </div>
      </main>
    </div>
  );
}
