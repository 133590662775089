import React, { useContext } from "react";

import Checkbox from "../../components/ui/Checkbox/Checkbox";
import { selectKeys } from "../../library/object";
import { ReadOnlyContext } from "../../App";

import SalesExchangeRateInput from "./SalesExchangeRateInput";
import PurchaseExchangeRateInput from "./PurchaseExchangeRateInput";

import * as s from "./FilterPanel.module.css";
import * as s2 from "./PGTable.module.css";

export default function Currencies({
  countryCode,
  currency,
  navigate,
  params,
  priceListId,
  purchaseExchangeRates,
  saleExchangeRate,
  urlCreator,
}) {
  const { showDiff, showDiffPercent, modelExRate } = params;
  const readOnly = useContext(ReadOnlyContext);

  const saleRateFromParams = params["saleExchangeRate"];
  const currentSaleRate =
    modelExRate && saleRateFromParams != null
      ? saleRateFromParams
      : saleExchangeRate;

  const ratesFromParams = selectKeys(
    params,
    Object.keys(purchaseExchangeRates || {}).reduce(
      (set, i) => set.add(i),
      new Set()
    )
  );
  const currentPurchaseRates = modelExRate
    ? { ...purchaseExchangeRates, ...ratesFromParams }
    : purchaseExchangeRates;

  return (
    <div className={s.filterPanel}>
      <div className="flex">
        <div className="self-center">Nákupní:&nbsp;</div>
        {Object.entries(currentPurchaseRates || {}).filter(([c]) => c !== 'CZK').map(
          ([currency, exchangeRate]) => {
            return (
              <PurchaseExchangeRateInput
                key={currency}
                currency={currency}
                exchangeRate={exchangeRate}
                purchaseExchangeRates={purchaseExchangeRates}
                disabled={!modelExRate || readOnly}
                onChange={e => {
                  console.log(currency, e.target.value)
                  navigate(
                    urlCreator(priceListId, countryCode, {
                      ...params,
                      [currency]: e.target.value.replace(",", ".")
                    })
                  )
                }}
              />
            );
          }
        )}
        {currency !== "CZK" && (
          <SalesExchangeRateInput
            currency={currency}
            saleExchangeRate={saleExchangeRate}
            disabled={!modelExRate || readOnly}
            currentSaleRate={currentSaleRate}
            onChange={e => navigate(
              urlCreator(priceListId, countryCode, {
                ...params,
                saleExchangeRate: e.target.value.replace(",", "."),
              })
            )}
          />
        )}
        <Checkbox
          id="modelExchangeRate"
          label="MODELOVAT KURZ"
          disabled={readOnly || (showDiff && showDiffPercent)}
          labelClass={s2.checkboxLabel}
          className="mr-8"
          labelPositionLeft={true}
          checked={modelExRate && !readOnly}
          onChange={() => {
            navigate(
              urlCreator(priceListId, countryCode, {
                ...params,
                modelExRate: !modelExRate,
              })
            );
          }}
        />
      </div>
    </div>
  )
}