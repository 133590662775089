import React from "react";
import { RefreshCcw, Sidebar, X, Download } from "react-feather";
import { Link } from "@reach/router";
import classNames from "classnames";

import Text from "../../components/ui/Text/Text";
import Button from "../../components/ui/Button/Button";
import { apiExportPricing, pricingUrl } from "../../library/routes";
import * as format from "../../library/format";
import { useGetPricingKPI } from "../../requests/getPricingKPI";
import { indexToChar } from "../Matrix/Configuration";

import * as s from "./PlanOverviewPanel.module.css";

function GetMBR(sales, purchase) {
  if (sales === 0) return '-'
  return Math.round(((sales - purchase) / sales) * 10000) / 100;
}

function OverviewTable({
  levels,
  loading,
  refetch,
  modelExRateLevels,
}) {
  const salesTotal = levels.reduce((acc, l) => {
    return acc + l.sales;
  }, 0);
  const salesPredictedTotal = levels.reduce((acc, l) => {
    return acc + l.salesPredicted;
  }, 0);
  const purchaseTotal = levels.reduce((acc, l) => {
    return acc + l.purchase;
  }, 0);
  const purchasePredictedTotal = levels.reduce((acc, l) => {
    return acc + l.purchasePredicted;
  }, 0);
  const marginTotal = levels.reduce((acc, l) => {
    return acc + l.sales - l.purchase;
  }, 0);
  const marginPredictedTotal = levels.reduce((acc, l) => {
    return acc + l.salesPredicted - l.purchasePredicted;
  }, 0);
  /////////////
  const mexrSalesTotal = (modelExRateLevels || []).reduce((acc, l) => {
    return acc + l.sales;
  }, 0);
  const mexrSalesPredictedTotal = (modelExRateLevels || []).reduce((acc, l) => {
    return acc + l.salesPredicted;
  }, 0);
  const mexrPurchaseTotal = (modelExRateLevels || []).reduce((acc, l) => {
    return acc + l.purchase;
  }, 0);
  const mexrPurchasePredictedTotal = (modelExRateLevels || []).reduce((acc, l) => {
    return acc + l.purchasePredicted;
  }, 0);
  const mexrMarginTotal = (modelExRateLevels || []).reduce((acc, l) => {
    return acc + l.sales - l.purchase;
  }, 0);
  const mexrMarginPredictedTotal = (modelExRateLevels || []).reduce((acc, l) => {
    return acc + l.salesPredicted - l.purchasePredicted;
  }, 0);
  

  return (
    <>
      <table className={classNames(s.table, { loadingOpacity: loading })}>
        <thead>
          <tr className={s.bordered}>
            <th className="relative">
              <Button
                className={s.refreshIconBtn}
                loading={loading}
                onClick={refetch}
              >
                <RefreshCcw
                  className={classNames(s.refreshIcon, { [s.rotating]: loading })}
                />
              </Button>
            </th>
            {levels.map((level, i) => (
              <th key={i}>{indexToChar(level.level)}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={s.rowTitle}>Turnover (CZK)</td>
            {levels.map((level, i) => {
              return <td key={i}>
                {format.number(Math.round(level.sales))} Kč
                {modelExRateLevels && (
                  <>
                    <br /><span className={s.demoNumber}>
                      {format.number(Math.round(modelExRateLevels[i].sales))} Kč
                    </span>
                  </>
                )}
              </td>;
            })}
            <td>
              {format.number(Math.round(salesTotal))} Kč
              {modelExRateLevels && (
                <>
                  <br /><span className={s.demoNumber}>
                    {format.number(Math.round(mexrSalesTotal))} Kč
                  </span>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className={s.rowTitle}>Margin (CZK)</td>
            {levels.map((level, i) => {
              return (
                <td key={i}>
                  {format.number(Math.round(level.sales - level.purchase))} Kč
                  {modelExRateLevels && (
                    <>
                      <br /><span className={s.demoNumber}>
                        {format.number(Math.round((modelExRateLevels[i].sales - modelExRateLevels[i].purchase)))} Kč
                      </span>
                    </>
                  )}
                </td>
              );
            })}
            <td>
              {format.number(Math.round(marginTotal))} Kč
              {modelExRateLevels && (
                <>
                  <br /><span className={s.demoNumber}>
                    {format.number(Math.round(mexrMarginTotal))} Kč
                  </span>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className={s.rowTitle}>Purchase Price (CZK)</td>
            {levels.map((level, i) => {
              return (
                <td key={i}>
                  {format.number(Math.round(level.purchase))} Kč
                  {modelExRateLevels && (
                    <>
                      <br /><span className={s.demoNumber}>
                        {format.number(Math.round(modelExRateLevels[i].purchase))} Kč
                      </span>
                    </>
                  )}
                </td>
              );
            })}
            <td>
              {format.number(Math.round(purchaseTotal))} Kč
              {modelExRateLevels && (
                <>
                  <br /><span className={s.demoNumber}>
                    {format.number(Math.round(mexrPurchaseTotal))} Kč
                  </span>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className={s.rowTitle}>Margin (%)</td>
            {levels.map((level, i) => {
              return (
                <td key={i}>
                  <Text size="1rem" weight="600">
                    {GetMBR(level.sales, level.purchase)} %
                  </Text>
                  {modelExRateLevels && (
                    <>
                      <br /><span className={s.demoNumber}>
                        {GetMBR(modelExRateLevels[i].sales, modelExRateLevels[i].purchase)} %
                      </span>
                    </>
                  )}
                </td>
              );
            })}
            <td>
              <Text size="1rem" weight="600">
                {GetMBR(salesTotal, purchaseTotal)} %
                {modelExRateLevels && (
                  <>
                    <br /><span className={s.demoNumber}>
                      {GetMBR(mexrSalesTotal, mexrPurchaseTotal)} %
                    </span>
                  </>
                )}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="h-4" />
      <span style={{ fontSize: '.75rem', fontWeight: '600', paddingLeft: '.5rem' }}>Predikce:</span>
      <table className={classNames(s.table, { loadingOpacity: loading })}>
        <tbody>
          <tr>
            <td className={s.rowTitle}>Turnover (CZK)</td>
            {levels.map((level, i) => {
              return <td key={i}>
                {format.number(Math.round(level.salesPredicted))} Kč
                {modelExRateLevels && (
                  <>
                    <br /><span className={s.demoNumber}>
                      {format.number(Math.round(modelExRateLevels[i].salesPredicted))} Kč
                    </span>
                  </>
                )}
              </td>;
            })}
            <td>
              {format.number(Math.round(salesPredictedTotal))} Kč
              {modelExRateLevels && (
                <>
                  <br /><span className={s.demoNumber}>
                    {format.number(Math.round(mexrSalesPredictedTotal))} Kč
                  </span>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className={s.rowTitle}>Margin (CZK)</td>
            {levels.map((level, i) => {
              return (
                <td key={i}>
                  {format.number(Math.round((level.salesPredicted - level.purchasePredicted)))} Kč
                  {modelExRateLevels && (
                    <>
                      <br /><span className={s.demoNumber}>
                        {format.number(Math.round((modelExRateLevels[i].salesPredicted - modelExRateLevels[i].purchasePredicted)))} Kč
                      </span>
                    </>
                  )}
                </td>
              );
            })}
            <td>
              {format.number(Math.round(marginPredictedTotal))} Kč
              {modelExRateLevels && (
                <>
                  <br /><span className={s.demoNumber}>
                    {format.number(Math.round(mexrMarginPredictedTotal))} Kč
                  </span>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className={s.rowTitle}>Purchase Price (CZK)</td>
            {levels.map((level, i) => {
              return (
                <td key={i}>
                  {format.number(Math.round(level.purchasePredicted))} Kč
                  {modelExRateLevels && (
                    <>
                      <br /><span className={s.demoNumber}>
                        {format.number(Math.round(modelExRateLevels[i].purchasePredicted))} Kč
                      </span>
                    </>
                  )}
                </td>
              );
            })}
            <td>
              {format.number(Math.round(purchasePredictedTotal))} Kč
              {modelExRateLevels && (
                <>
                  <br /><span className={s.demoNumber}>
                    {format.number(Math.round(mexrPurchasePredictedTotal))} Kč
                  </span>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className={s.rowTitle}>Margin (%)</td>
            {levels.map((level, i) => {
              return (
                <td key={i}>
                  <Text size="1rem" weight="600">
                    {GetMBR(level.salesPredicted, level.purchasePredicted)} %
                    {modelExRateLevels && (
                      <>
                        <br /><span className={s.demoNumber}>
                          {GetMBR(modelExRateLevels[i].salesPredicted, modelExRateLevels[i].purchasePredicted)} %
                        </span>
                      </>
                    )}
                  </Text>
                </td>
              );
            })}
            <td>
              <Text size="1rem" weight="600">
                {GetMBR(salesPredictedTotal, purchasePredictedTotal)} %
                {modelExRateLevels && (
                  <>
                    <br /><span className={s.demoNumber}>
                      {GetMBR(mexrSalesPredictedTotal, mexrPurchasePredictedTotal)} %
                    </span>
                  </>
                )}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default function PlanOverviewPanel({
  showPlanOverview,
  priceListId,
  pricingColl,
  purchaseExchangeRates,
  salesExchangeRates,
  countryCode,
  country,
  urlParams,
  navigate,
  modelExRate,
}) {
  const togglePanelUrl = pricingUrl(priceListId, countryCode, {
    ...urlParams,
    showPlanOverview: !showPlanOverview
  });

  const cleanedKPIUrlParams = {
    ...urlParams,
    saleExchangeRate: undefined,
    modelExRate: undefined,
    'sort[value]': undefined,
    'sort[direction]': undefined,
    'sort[x]': undefined,
    'sort[y]': undefined,
    showPlanOverview: undefined,
  };
  const cleanedModelKPIUrlParams = {
    ...cleanedKPIUrlParams,
  }
  Object.keys(purchaseExchangeRates || {}).forEach(key => {
    if (urlParams[key]) {
      // cleanedKPIUrlParams[`purchaseExchangeRate[${key}]`] = urlParams[key]
      cleanedModelKPIUrlParams[`purchaseExchangeRate[${key}]`] = urlParams[key]
      delete cleanedKPIUrlParams[key]
    }
  })
  // mind the plural is saleS - `salesExchangeRate`
  if (country.currency) cleanedModelKPIUrlParams[`salesExchangeRate[${country.currency}]`] = urlParams.saleExchangeRate
  
  const {
    data: pricingKPI = {},
    isFetching: loading,
    refetch
  } = useGetPricingKPI({
    enabled: !!showPlanOverview,
    countryCode,
    priceListId,
    ...cleanedKPIUrlParams
  });
  const {
    data: modelPricingKPI = {},
    isFetching: loadingModelPricingKPI,
    refetch: refetchModelPricingKPI,
  } = useGetPricingKPI({
    enabled: !!showPlanOverview && !!modelExRate,
    countryCode,
    priceListId,
    ...cleanedModelKPIUrlParams
  });

  return (
    <section>
      <div className="flex justify-end items-center">
        <Link
          to={''}
          className={`${s.toggleLink} mr-8`}
          onClick={e => {
            e.preventDefault();
            window.location = apiExportPricing(
              priceListId,
              countryCode,
              urlParams.commodity,
              urlParams
            );
          }}
        >
          <Text size="0.875rem" className="pr-1">
            Exportovat filtrované položky
          </Text>
          <Download size="14" className={s.icon} />
        </Link>
        <Link
          to={togglePanelUrl}
          className={s.toggleLink}
          onClick={e => {
            e.preventDefault();
            navigate(togglePanelUrl);
          }}
        >
          <Text size="0.875rem" className="pr-1">{`${
            showPlanOverview ? "Skrýt" : "Zobrazit"
          } celkový přehled`}</Text>
          {showPlanOverview ? (
            <X size="14" className={s.icon} />
          ) : (
            <Sidebar size="14" className={`${s.icon} transform rotate-90`} />
          )}
        </Link>
      </div>
      {showPlanOverview && (
        <div className={s.planOverviewPanel}>

          {/*
          {!!Object.entries(currentPurchaseRates).length && (
            <div className={s.exchangeRateGrid}>
              <div className="flex">
                <div className="self-center">Nákupní:&nbsp;</div>
                {Object.entries(currentPurchaseRates).map(
                  ([currency, exchangeRate]) => {
                    return (
                      <PurchaseExchangeRateInput
                        key={currency}
                        currency={currency}
                        exchangeRate={exchangeRate // value}
                        purchaseExchangeRates={purchaseExchangeRates // ~label}
                        disabled={readOnly}
                        onBlur={e =>
                          navigate(
                            pricingUrl(priceListId, countryCode, {
                              ...urlParams,
                              [`kpiPurchaseExchangeRate_${currency}`]: e.target.value.replace(",", ".")
                            })
                          )
                        }
                      />
                    )
                  }
                )}
              </div>
              {country.currency !== "CZK" && (
                <SalesExchangeRateInput
                  currency={country.currency}
                  saleExchangeRate={saleExchangeRate // label}
                  disabled={readOnly}
                  currentSaleRate={currentSaleRate // value}
                  onBlur={e =>
                    navigate(
                      pricingUrl(priceListId, countryCode, {
                        ...urlParams,
                        kpiSaleExchangeRate: e.target.value.replace(",", ".")
                      })
                    )
                  }
                />
              )}
              <Button
                className={s.recalcExchangeRateButton}
                loading={loading}
                onClick={() => console.log('refetch')}
              >
                Přepočítat
              </Button>
            </div>
          )}
          */}

          <div className={s.planOverviewPanelContent}>
            {loading ? (
              <div className="inProgress">Načítá se</div>
            ) : (
              <div className="flex-1">
                <OverviewTable
                  levels={pricingKPI?.data?.levels}
                  loading={loading || loadingModelPricingKPI}
                  refetch={() => {
                    refetch()
                    modelExRate && refetchModelPricingKPI()
                  }}
                  modelExRateLevels={modelExRate && !loadingModelPricingKPI && modelPricingKPI?.data?.levels}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
