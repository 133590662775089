import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
        // process.env.NODE_ENV === "development" ? false : "always"
    }
  }
});
