import React, { useContext, useEffect, useState } from "react";
import "./GlobalErrors.css";
import { AlertCircle, XCircle } from "react-feather";
import _ from "lodash";
import { ErrorsContext } from "../../App";

function dumpError(error) {
  return error.message || JSON.stringify(error);
}

export default function GlobalErrors() {
  const errors = useContext(ErrorsContext);
  const someErrors = !_(errors)
    .filter((error, _) => error)
    .isEmpty();
  const [isShowed, setIsShowed] = useState(someErrors);
  useEffect(() => setIsShowed(someErrors), [someErrors]);

  return isShowed ? (
    <div className="GlobalErrorsWrapper">
      <div className="GlobalErrors fixedMaxSize">
        <AlertCircle className="globalErrorIcon" />
        <div className="globalErrorText">
          {new Date().toString()}
          {_(errors)
            .filter((error, _) => error)
            .map((error, key) => (
              <p key={key}>Server error occurred: {dumpError(error)}</p>
            ))
            .value()}
        </div>
        <XCircle
          className="closeBtn clickable"
          onClick={() => setIsShowed(false)}
        />
      </div>
    </div>
  ) : null;
}
