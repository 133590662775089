import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { Menu } from "react-feather";
import idx from "idx";

import IconColorful from "../../components/ui/IconColorful";
import Text from "../../components/ui/Text/Text";
import Select, { ActionSelect } from "../../components/ui/Select/Select";
import { openModal } from "../../store/actions";
import { countMargin, roundingRules } from "adler-utils/country";
import { pricingUrl, pricingUrlCurrency } from "../../library/routes";
import Button from "../../components/ui/Button/Button";
import Input from "../../components/ui/Input/Input";
import { selectKeys } from "../../library/object";
import * as format from "../../library/format";
import { OptionsPanel } from "./OptionsPanel";
import { levelLimit, matrixXToLevel } from "../../library/matrix";
import { useFreshState } from "../../library/hooks";
import { ReadOnlyContext } from "../../App";
import { useUpdatePricingGroupLeadingValue } from "../../requests/updatePricingGroupLeadingValue";
import { useAssignMatrixToPricingGroup } from "../../requests/assignMatrixToPricingGroup";
import { useDismissMatrixToPricingGroup } from "../../requests/dismissMatrixToPricingGroup";
import { translateFromLevel, indexToChar } from "../Matrix/Configuration";

import * as s from "./PGTable.module.css";

export function baseTitle(title) {
  return title.replace(/barevné|bílé/, "");
}

function differentBaseTitles(title, otherTitle) {
  return baseTitle(title) !== baseTitle(otherTitle);
}

function TierCellsForGroup({
  priceListId,
  countryCode,
  currencyPickerMode,
  group,
  column,
  showDiff,
  showDiffPercent,
  currentPurchaseRates,
  matrixConfigurations,
  purchaseExchangeRates,
  currentSaleRate,
  saleExchangeRate,
  salesExchangeRates,
  selectedCurrency, // only for currencyPickerMode
  countryRate,
  isLocked,
  modelExRate,
  readOnly,
  params,
  dispatch
}) {
  const groupVals = useMemo(
    () =>
      (group.price_list_group_values || []).find(
        v => v.level === column.level && v.limit === column.limit
      ) || {},
    [group, column]
  );
  const historyVal = useMemo(
    () =>
      (
        (group.history_price_list_values || []).find(
          v => v.level === column.level && v.limit === column.limit
        ) || {}
      ).value,
    [group, column]
  );

  const omitRounding = false; //countryCode === "CZ" && groupVals.edited;

  // const calculatedAmount = groupVals.value
  const calculatedAmount = !currencyPickerMode
    ? groupVals.value
    : groupVals.value * countryRate / salesExchangeRates[selectedCurrency];

  const [amount, setAmount] = useFreshState(
    omitRounding
      ? calculatedAmount
      : roundingRules(countryCode, calculatedAmount, column.level, column.limit)
  );

  const matrixDescendancyError = useMemo(() => {
    if (column.y === 1) return false

    let err = []
    for (let i = 0;i < group?.configuration_matrix?.width;i++) {
      const previousLevelGroupToCheck = (group.price_list_group_values || []).find(
        v => v.limit === (i + 1) && v.level === indexToChar(column.y - 1)
      )
      const currentLevelGroupToCheck = (group.price_list_group_values || []).find(
        v => v.limit === (i + 1) && v.level === column.level
      )
      if (previousLevelGroupToCheck && currentLevelGroupToCheck) {
        const check = !(currentLevelGroupToCheck.value > previousLevelGroupToCheck.value)
        if (!check) {
          err.push(`[${indexToChar(column.y - 1)}${previousLevelGroupToCheck.limit}: ${previousLevelGroupToCheck.value}, ${column.level}${currentLevelGroupToCheck.limit}: ${currentLevelGroupToCheck.value}]`)
        }
      }
    }
    return err.length ? err.join(', ') : false
  }, [group, column])

  const [prevAmount, setPrevAmount] = useState(amount)
  const [isEditing, setIsEditing] = useState(false)
  const preventSave = useRef(false)

  const leadingValues = Object.values(group.configuration_matrix?.leading_values || {})
  const leadingKeys = leadingValues.reduce(
    (acc, { x, y }) => acc.add(levelLimit(matrixXToLevel[y], x)),
    new Set()
  );

  const isKeyCell = leadingKeys.has(levelLimit(column.level, column.limit));
  const purchaseCurrency = group.v_pricing_group_purchase_price?.currency_code;
  const purchaseRate = purchaseExchangeRates[purchaseCurrency];
  const currentPurchaseRate = currentPurchaseRates[purchaseCurrency];
  const purchasePrice = group.v_pricing_group_purchase_price?.price;
  const currentWeightedAverage =
    currentSaleRate === countryRate
      ? groupVals.weighted_average
      // : (groupVals.weighted_average / countryRate) * (currencyPickerMode ? salesExchangeRates[column.currency] : currentSaleRate);
      : (groupVals.weighted_average / countryRate) * (currencyPickerMode ? salesExchangeRates[column.currency] : saleExchangeRate);
  const weightedAverage = groupVals.weighted_average;
  const historyValInCurrency = historyVal;
  const currentMbrPerc =
    Math.round(
      countMargin(
        currentWeightedAverage * (
          !currencyPickerMode
            ? currentSaleRate
            : (modelExRate && column.currency !== 'CZK' ? params['saleExchangeRate'] : salesExchangeRates[column.currency])
        ),
        purchasePrice,
        currentPurchaseRate
      ) * 1000
    ) / 10;

  const mbrPerc =
    !currencyPickerMode
    ? Math.round(
        countMargin(
          currentWeightedAverage * saleExchangeRate,
          purchasePrice,
          purchaseRate
        ) * 1000
      ) / 10
    : Math.round(
        countMargin(
          currentWeightedAverage * (modelExRate && column.currency !== 'CZK' ? params['saleExchangeRate'] : salesExchangeRates[column.currency]),
          purchasePrice,
          purchaseRate
        ) * 1000
      ) / 10;

  const showModelExRate = modelExRate && (!readOnly || currencyPickerMode);
  const {
    mutateAsync: updatePricingGroupLeadingValue
  } = useUpdatePricingGroupLeadingValue({
    priceListId,
    countryCode,
    ...params
  });

  return (
    <>
      {!currencyPickerMode && (
        <td
          className={classNames(s.numberCell, { "cursor-pointer": !readOnly })}
          onClick={() => {
            !readOnly &&
              dispatch(
                openModal({
                  type: "ResetToPredictionConfirm",
                  props: {
                    update: () =>
                      updatePricingGroupLeadingValue({
                        pricingGroupId: group.id,
                        value: historyValInCurrency ?? 0,
                        x: column.x,
                        y: column.y
                      })
                  }
                })
              );
          }}
        >
          {format.number(historyValInCurrency)}
        </td>
      )}
      <td
        className={classNames({
          [s.numberCell]: !isKeyCell,
          [s.keyCell]: isKeyCell,
          [s.errorVal]: (isKeyCell && groupVals.errorMsg) || matrixDescendancyError,
        })}
        title={groupVals.errorMsg + (matrixDescendancyError ? ' ' + matrixDescendancyError : '')}
      >
        {isKeyCell && group.pricing_group_setting ? (
          <Input
            type="number"
            disabled={isLocked || readOnly}
            value={
              isEditing
                ? amount
                : roundingRules(
                    countryCode,
                    amount || 0,
                    column.level,
                    column.limit
                  )
            }
            className={{
              [s.keyInput]: true,
              [s.errorVal]: matrixDescendancyError,
            }}
            noContainer={true}
            onChange={e => {
              setAmount(Number(e.target.value));
            }}
            onKeyDown={e => {
              if (e.key === "Enter") e.target.blur();
              else if (e.key === "Escape") {
                setAmount(prevAmount);
                preventSave.current = true;
                e.target.blur();
              }
            }}
            onFocus={() => setIsEditing(true)}
            onBlur={e => {
              setIsEditing(false);
              setAmount(
                roundingRules(
                  countryCode,
                  amount || 0,
                  column.level,
                  column.limit
                )
              );
              if (!preventSave.current && prevAmount !== amount) {
                updatePricingGroupLeadingValue({
                  pricingGroupId: group.id,
                  value: amount,
                  x: column.x,
                  y: column.y
                });
              }
              setPrevAmount(e.target.value);
              preventSave.current = false;
            }}
          />
        ) : (
          format.number(
            roundingRules(countryCode, amount, column.level, column.limit)
          )
        )}
      </td>
      {!currencyPickerMode && showDiff && (
        <td className={classNames(s.numberCell)}>
          {format.number(amount - historyValInCurrency)}
        </td>
      )}
      {!currencyPickerMode && showDiffPercent && (
        <td className={classNames(s.numberCell)}>
          {format.number(Math.round(((amount - historyValInCurrency) / historyValInCurrency) * 10000) / 100)} %
        </td>
      )}
      <td
        className={classNames(s.numberCell, {
          [s.leftSeparator]: !showModelExRate,
          [s.errorVal]: mbrPerc < 20,
          [s.alertVal]: mbrPerc >= 20 && mbrPerc < 25
        })}
      >
        {weightedAverage ? format.number(mbrPerc) : "-"}
      </td>
      {!currencyPickerMode && showModelExRate && (
        <td
          className={classNames(s.numberCell, s.leftSeparator, {
            [s.errorVal]: currentMbrPerc < 20,
            [s.alertVal]: currentMbrPerc >= 20 && currentMbrPerc < 25
          })}
        >
          {currentWeightedAverage ? format.number(currentMbrPerc) : "-"}
        </td>
      )}
    </>
  );
}

export default function PGTable({
  currencyPickerMode,
  selectedCols,
  matrixConfigurationsSrc,

  pricingColl,
  loading,
  matrixConfigurations = {},
  priceListId,
  dispatch,
  params,
  navigate,
  country,
  purchaseExchangeRates,
  salesExchangeRates,
  selectedCurrency,
  setMbrOptions,
  isLocked,
}) {
  let readOnly = useContext(ReadOnlyContext);
  readOnly = currencyPickerMode || readOnly;

  const countryCode = country?.code || 'CZ'; // the `CZ` fallback should only happen for currencyPickerMode
  const countryCurrency = country?.currency || 'CZK'; // the `CZK` fallback should only happen for currencyPickerMode
  const countryName = country?.name || '---';

  const urlCreator = currencyPickerMode ? pricingUrlCurrency : pricingUrl;

  const { items, page, limit, count } = pricingColl[0]?.data || {};
  const { showDiff, showDiffPercent, modelExRate } = params;

  const pageCount = Math.ceil(count / limit);
  const matrices = useMemo(() => {
    if (matrixConfigurations?.data) {
      const options = matrixConfigurations.data.map(m => ({
        title: m.name,
        value: m.id
      }));
      options.unshift({ title: Select.NO_VALUE, value: null });
      return options;
    } else {
      return [];
    }
  }, [matrixConfigurations]);

  const columnsTemplate = useMemo(() => {
    if (currencyPickerMode) {
      return selectedCols.map((col, index) => ({
        level: col.matrix[0],
        limit: parseInt(col.matrix[1], 10),
        x: parseInt(col.matrix[1]),
        y: translateFromLevel(col.matrix[0]),
        country: col.country,
        currency: col.currency,
        index,
      }))
    }
    ////////

    const columnsTemplate = [
      { limit: 1, level: matrixXToLevel[1], x: 1, y: 1 }
    ];

    const uniqueMatrices = (items || []).reduce((acc, group) => {
      if (group.configuration_matrix?.id) {
        acc[group.configuration_matrix.id] = group.configuration_matrix;
      }
      return acc;
    }, {});
    const uniqueLevelsObj = Object.values(uniqueMatrices).reduce((acc, matrix) => {
      const lv = Object.values(matrix.leading_values)
      for (let i = 0, l = lv.length;i < l;i++) {
        if (lv[i].x <= matrix.width && lv[i].y <= matrix.height && (lv[i].x !== 1 || lv[i].y !== 1)) {
          acc[`${lv[i].x}${lv[i].y}`] = {
            level: matrixXToLevel[lv[i].y],
            limit: lv[i].x,
            x: lv[i].x,
            y: lv[i].y,
          };
        }
      }
      acc[`${matrix.width}${matrix.height}`] = {
        level: matrixXToLevel[matrix.height],
        limit: matrix.width,
        x: matrix.width,
        y: matrix.height,
      };
      return acc;
    }, {});
    columnsTemplate.push(...Object.values(uniqueLevelsObj))

    return columnsTemplate.sort((ct1, ct2) => `${ct1.y}${ct1.x}` < `${ct2.y}${ct2.x}` ? -1 : 1);
  }, [items, currencyPickerMode, selectedCols]);

  useEffect(() => {
    setMbrOptions(columnsTemplate);
  }, [columnsTemplate, setMbrOptions])

  const {
    mutateAsync: assignMatrixToPricingGroup
  } = useAssignMatrixToPricingGroup({
    priceListId,
    countryCode,
    ...params
  });
  const {
    mutateAsync: dismissMatrixToPricingGroup
  } = useDismissMatrixToPricingGroup({
    priceListId,
    countryCode,
    ...params
  });

  if (loading) {
    return (
      <div className="p-10 text-center">
        <em>
          <Text loading>Načítá se</Text>
        </em>
      </div>
    );
  }
  

  if (items && salesExchangeRates) {
    const purchaseCurrencies = items.reduce(
      (set, i) =>
        set.add(idx(i, i => i.v_pricing_group_purchase_price.currency_code)),
      new Set()
    );

    const ratesFromParams = selectKeys(params, purchaseCurrencies);
    const currentPurchaseRates = modelExRate
      ? { ...purchaseExchangeRates, ...ratesFromParams }
      : purchaseExchangeRates;

    const saleExchangeRate = salesExchangeRates[countryCurrency];
    const saleRateFromParams = params["saleExchangeRate"];
    const currentSaleRate =
      modelExRate && saleRateFromParams != null
        ? parseFloat(saleRateFromParams)
        : saleExchangeRate;

    const pagerLength = 7;
    let pageMin = (page - 3) < 1 ? 1 : (page - 3);
    let pageMax = (pageMin + pagerLength) > pageCount ? pageCount : (pageMin + pagerLength);
    if (pageMax - pageMin < pagerLength) {
      pageMin = pageMax - pagerLength;
      pageMin = pageMin < 1 ? 1 : pageMin;
    }
    const range = pageMax - pageMin + 1;

    return (
      <section className={s.tableSection}>
        {!currencyPickerMode && (
          <OptionsPanel
            priceListId={priceListId}
            countryCode={countryCode}
            currency={countryCurrency}
            params={params}
            navigate={navigate}
            purchaseExchangeRates={purchaseExchangeRates}
            currentPurchaseRates={'unused'/*selectKeys(
              currentPurchaseRates,
              purchaseCurrencies
            )*/}
            saleExchangeRate={saleExchangeRate}
            currentSaleRate={currentSaleRate}
          />
        )}
        <div>
          <table className={s.table}>
            <thead>
              <tr className={s.headerRow}>
                <th className={`${s.headerGroupCell} ${s.fitCol}`}>KOM.</th>
                <th className={s.headerGroupCell}>CENÍKOVÁ SKUPINA</th>
                {!currencyPickerMode && (
                  <th
                    className={classNames(
                      `${s.headerGroupCell} ${s.thickLeftSeparator} w-1`,
                      { "cursor-pointer": !readOnly }
                    )}
                    onClick={() => {
                      if (readOnly) return;

                      dispatch(
                        openModal({
                          type: "CommoditiesMatrixBatchUpdate",
                          props: {
                            countryCode,
                            matrices,
                            priceListId,
                            commoditiesId: items.map(c => c.commodity_code),
                            countryName: countryName,
                            params,
                            success: pricingColl?.[0].refetch,
                          }
                        })
                      );
                    }}
                  >
                    <div className="flex items-center">
                      KONF. <Menu size={16} color="#445e9c" className="ml-2" />
                    </div>
                  </th>
                )}
                {columnsTemplate.map((gv, i) => {
                  const levelLimitVal = levelLimit(gv.level, gv.limit);
                  const showModelExRate = modelExRate && (!readOnly || currencyPickerMode);
                  return (
                    <React.Fragment key={i}>
                      {!currencyPickerMode && (
                        <th
                          className={classNames(s.headerHistoryCell, {
                            "cursor-pointer": !readOnly
                          })}
                          onClick={() =>
                            !readOnly &&
                            dispatch(
                              openModal({
                                type: "BulkResetToPredictionConfirm",
                                props: {
                                  group: gv,
                                  priceListId,
                                  countryCode,
                                  urlParams: params,
                                  pricingGroupIds: items.filter(item => {
                                    return !!Object.values(item.configuration_matrix?.leading_values || {}).find(lv => lv.x === gv.x && lv.y === gv.y)
                                  }).map(
                                    pg => pg.id
                                  ),
                                  refetch: pricingColl[0].refetch
                                }
                              })
                            )
                          }
                        >
                          {levelLimitVal}
                        </th>
                      )}
                      <th
                        className={classNames(s.headerCurrentCell, {
                          [s.headerKeyCell]: false /* leadingKeys.has(levelLimitVal) */,
                          "cursor-pointer": !readOnly,
                        })}
                        onClick={
                          !readOnly /* && leadingKeys.has(levelLimitVal) */
                            ? () =>
                                dispatch(
                                  openModal({
                                    type: "ColumnBatchUpdate",
                                    props: {
                                      group: gv,
                                      priceListId,
                                      countryCode,
                                      urlParams: params,
                                      currency: countryCurrency,
                                      pricingGroupIds: items.filter(item => {
                                        return !!Object.values(item.configuration_matrix?.leading_values || {}).find(lv => lv.x === gv.x && lv.y === gv.y)
                                      }).map(
                                        pg => pg.id
                                      ),
                                      refetch: pricingColl[0].refetch
                                    }
                                  })
                                )
                            : null
                        }
                        title="Klikni pro hromadnou editaci sloupce"
                      >
                        {levelLimitVal}
                        {currencyPickerMode && (
                          <div className={s.countryLabel}>{selectedCols[i]?.country}</div>
                        )}
                      </th>
                      {showDiff && (
                        <th className={classNames(s.headerDiffOrMBR)}>
                          Rozdíl
                        </th>
                      )}
                      {showDiffPercent && (
                        <th className={classNames(s.headerDiffOrMBR)}>
                          Rozdíl %
                        </th>
                      )}
                      <th
                        className={classNames(s.headerDiffOrMBR, {
                          [s.leftSeparator]: !showModelExRate
                        })}
                      >
                        MBR (%)
                      </th>
                      {showModelExRate && (
                        <th
                          className={classNames(
                            s.headerDiffOrMBR,
                            s.leftSeparator
                          )}
                        >
                          model. MBR (%)
                        </th>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {items.map((group, i) => {
                const prev = i > 0 && items[i - 1];
                return (
                  <tr
                    key={group.id}
                    className={classNames(s.row, {
                      [s.sameTitleSeparator]:
                        prev && differentBaseTitles(prev.title, group.title),
                      [s.commoditySeparator]:
                        prev && prev.commodity_code !== group.commodity_code
                    })}
                  >
                    <td className={s.cell}>
                      <div className="flex items-center">
                        <IconColorful
                          colorful={group.is_colorful}
                          className="mr-3"
                        />
                        <Text size="1.125rem">{group.commodity_code}</Text>
                      </div>
                    </td>
                    <td className={s.cell}>
                      <div className={s.cellFlexWrapper}>
                        <Text size="0.875rem" weight="600">
                          {group.title}
                        </Text>
                        {group.rowErrorMsg && (
                          <div className={s.rowError} title={group.rowErrorMsg}>
                            !
                          </div>
                        )}
                      </div>
                    </td>
                    {!currencyPickerMode && (
                      <td className={s.thickLeftSeparator}>
                        <ActionSelect
                          id="commodity"
                          options={matrices}
                          className={s.matrixSelect}
                          noContainer={true}
                          value={
                            group?.pricing_group_setting
                              ?.configuration_matrix_id || Select.NO_VALUE
                          }
                          onChange={({ target: { value } }) => {
                            if (!value || value === Select.NO_VALUE) {
                              dismissMatrixToPricingGroup({
                                pricingGroupId: group.id
                              });
                            } else {
                              assignMatrixToPricingGroup({
                                pricingGroupId: group.id,
                                matrixId: value
                              });
                            }
                          }}
                        />
                      </td>
                    )}
                    {columnsTemplate.map((column, colIndex) => (
                      <TierCellsForGroup
                        key={`${column.index}-${column.x}-${column.y}`}
                        group={currencyPickerMode ? (pricingColl[column.index]?.data?.items[i] || {}) : group}
                        column={column}
                        matrixConfigurations={matrixConfigurations?.data || matrixConfigurationsSrc[selectedCols[column.index].country]}
                        showDiff={showDiff}
                        showDiffPercent={showDiffPercent}
                        priceListId={priceListId}
                        countryCode={selectedCols?.[colIndex]?.country || countryCode}
                        dispatch={dispatch}
                        currencyPickerMode={currencyPickerMode}
                        currentPurchaseRates={currentPurchaseRates}
                        purchaseExchangeRates={purchaseExchangeRates}
                        currentSaleRate={currentSaleRate}
                        selectedCurrency={selectedCurrency}
                        saleExchangeRate={saleExchangeRate}
                        salesExchangeRates={salesExchangeRates}
                        countryRate={salesExchangeRates[column.currency || countryCurrency]}
                        isLocked={isLocked}
                        modelExRate={modelExRate}
                        readOnly={readOnly}
                        params={params}
                      />
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {pageCount > 1 && (
          <div className={s.pagination}>
            {pageMin !== 1 && (
              <>
                <Button
                  secondary
                  className={s.paginationBtn}
                  onClick={() => {
                    navigate(
                      urlCreator(priceListId, countryCode, {
                        ...params,
                        page: 1
                      })
                    );
                  }}
                >
                  1
                </Button>
                {pageMin !== 2 && <>&ensp;..&ensp;</>}
              </>
            )}
            {Array.from(Array(range)).map((e,i) => {
              const pageNumber = i + pageMin;
              return <Button
                key={i}
                primary={pageNumber === page ? "true" : "false"}
                secondary={pageNumber !== page}
                className={s.paginationBtn}
                onClick={() => {
                  navigate(
                    urlCreator(priceListId, countryCode, {
                      ...params,
                      page: pageNumber
                    })
                  );
                }}
              >
                {`${pageNumber}`}
              </Button>
            })}
            {pageMax !== pageCount && (
              <>
                {pageMax !== (pageCount - 1) && <>&ensp;..&ensp;</>}
                <Button
                  secondary
                  className={s.paginationBtn}
                  onClick={() => {
                    navigate(
                      urlCreator(priceListId, countryCode, {
                        ...params,
                        page: pageCount
                      })
                    );
                  }}
                >
                  {`${pageCount}`}
                </Button>
              </>
            )}
          </div>
        )}
      </section>
    );
    // } else if ((items && !leading_x) || !leading_y) {
    //   return "Pro tuto zemi neexistuje žádná matice.";
  } else {
    return "Žádná data";
  }
}
